import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import KongsbergTableSpecifications from "./specifications"
import KongsbergTableToolHeads from "./tool-heads"
import KongsbergTableOverview from "./overview"

import CTA from "../../components/cta"

export const query = graphql`
  query TableQuery($id: ID!) {
    strapi {
      table: kongsbergTable(id: $id) {
        id
        title
        slug
        name
        description
        image {
          id
          alternativeText
          staticFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 880)
            }
          }
        }
        usedFor {
          ideas {
            title
            list
          }
        }
        technicalSpecification
        maxSpeed
        maxMaterialSize
        maxAcceleration
        toolHeadsSection {
          title
          subTitle
          toolHeads {
            name
            description
            image {
              id
              alternativeText
              staticFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, width: 640)
                }
              }
            }
          }
        }
        sections {
          __typename
          ... on Strapi_ComponentSectionsHeading {
            large
            sub
            heading
          }
          ... on Strapi_ComponentSectionsSimpleShowCase {
            alignImageLeft
            badge
            bigger
            heading
            sub
            videoLink
            action {
              link
              text
            }
            image {
              id
              alternativeText
              staticFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
          ... on Strapi_ComponentSectionsContainedShowCase {
            id
            alignLeft
            heading
            text
            action {
              link
              text
            }
            image {
              id
              alternativeText
              staticFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
          ... on Strapi_ComponentSectionsFullWidthVideo {
            id
            videoLink
          }
        }
      }
    }
  }
`

const TableTemplate = ({
  location,
  data: {
    strapi: { table },
  },
}) => {
  return (
    <Layout>
      <SEO title={table.name} />
      <div className="bg-brand-100 relative">
        <div className="absolute z-0 top-0 square-float flex justify-center w-full h-full overflow-hidden">
          <div className="absolute square bg-brand-200" />
          <div className="absolute square bg-brand-50" />
        </div>

        <div className="container relative mx-auto z-10">
          <div className="relative z-10 pt-48 pb-32 text-center">
            <div className="heading-sm mb-0">
              {table.name.split(" ").slice(0, -1)}{" "}
              <span className="text-brand-500">
                {table.name.split(" ").pop()}
              </span>
            </div>
            <h1 className="max-w-4xl mx-auto heading-xl">{table.title}</h1>
            <p
              className="max-w-4xl mx-auto text-2xl"
              dangerouslySetInnerHTML={{
                __html: table.description.replace(/\n/g, "<br />"),
              }}
            />
            <div className="mt-8">
              <Link to="/kontakt" className="btn-secondary">
                Snak med en ekspert
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="border-t border-b border-brand-200 py-1.5 max-w-full overflow-x-auto">
        <div className="mx-auto px-4 py-1.5 text-center">
          <Link
            to={`/${table.slug}`}
            activeClassName="bg-brand-200 hover:bg-brand-200"
            className="whitespace-nowrap text-base font-semibold uppercase py-2 px-5 rounded text-brand-700 hover:bg-brand-100"
          >
            Overblik
          </Link>
          {table.toolHeadsSection && (
            <Link
              to={`/${table.slug}/tool-heads`}
              activeClassName="bg-brand-200 hover:bg-brand-200"
              className="whitespace-nowrap text-base font-semibold uppercase py-2 px-5 rounded text-brand-700 hover:bg-brand-100"
            >
              Tool heads
            </Link>
          )}
          <Link
            to={`/tools?table=${table.id}`}
            activeClassName="bg-brand-200 hover:bg-brand-200"
            className="whitespace-nowrap text-base font-semibold uppercase py-2 px-5 rounded text-brand-700 hover:bg-brand-100"
          >
            Værktøj
          </Link>
          <Link
            to={`/${table.slug}/specifikationer`}
            activeClassName="bg-brand-200 hover:bg-brand-200"
            className="whitespace-nowrap text-base font-semibold uppercase py-2 px-5 rounded text-brand-700 hover:bg-brand-100"
          >
            Tekniske specifikation
          </Link>
        </div>
      </div>
      <div className="">
        {location.pathname.split("/").pop() === "tool-heads" ? (
          <KongsbergTableToolHeads table={table} />
        ) : location.pathname.split("/").pop() === "specifikationer" ? (
          <KongsbergTableSpecifications table={table} />
        ) : (
          <KongsbergTableOverview table={table} />
        )}
      </div>

      <CTA />
    </Layout>
  )
}

export default TableTemplate

import React from "react"

/*
const specification = {
  headers: ["X20", "X22", "X24", "X44", "X46", "X48"],
  disclaimers: [
    "(1) Mål med revolving workstation i sin standard position",
    "(2) Conveyor feeder tilføjer marginalt til længden",
    "(3) Omkring det totale arbejdsområde, med standard traverse clearance",
    "(4) Kan blive reduceret ved valg af værktøj og konfigarationer",
    "(5) Målt uden skæreunderlag. Maksimal skæredybde er afhængig af værktøj",
  ],
  rows: [
    {
      title: "Arbejdsområde",
      data: [
        { data: "1680 x 1270 mm" },
        { data: "1680 x 2190 mm" },
        { data: "1680 x 3200 mm" },
        { data: "2210 x 3200 mm" },
        { data: "2210 x 4800 mm" },
        { data: "2210 x 6550 mm" },
      ],
    },
    {
      title: "Maksimal materiale størrelse",
      data: [
        { data: "1740 x 1750 mm" },
        { data: "1740 x 2570 mm" },
        { data: "1740 x 3575 mm" },
        { data: "2270 x 3575 mm" },
        { data: "2270 x 5250 mm" },
        { data: "2270 x 6930 mm" },
      ],
    },
    {
      title: "Maksimal materiale bredde med conveyor system",
      data: [
        { data: "1680 mm" },
        { data: "1680 mm" },
        { data: "1680 mm" },
        { data: "2210 mm" },
        { data: "2210 mm" },
        { data: "N/A" },
      ],
    },
    {
      title: "Overordnet dimension m. frontpanel",
      data: [
        { data: "2780 x 2450 mm" },
        { data: "2780 x 3040 mm" },
        { data: "2780 x 4050 mm" },
        { data: "3300 x 4050 mm" },
        { data: "N/A" },
        { data: "N/A" },
      ],
    },
    {
      title: "Overordnet dimension m. revolving work station (1)(2)",
      data: [
        { data: "3600 x 2160 mm" },
        { data: "3600 x 2950 mm" },
        { data: "3600 x 3960 mm" },
        { data: "4070 x 3960 mm" },
        { data: "4070 x 5640 mm" },
        { data: "4070 x 7320 mm" },
      ],
    },
    {
      title: "Vægt",
      data: [
        { data: "455 kg" },
        { data: "525 kg" },
        { data: "630 kg" },
        { data: "815 kg" },
        { data: "1150 kg" },
        { data: "1485 kg" },
      ],
    },
    {
      title: "Præcision af position (3)",
      data: [
        { data: "± 200 µm", span: 2 },
        { data: "± 250 µm" },
        { data: "± 300 µm" },
        { data: "± 350 µm" },
        { data: "± 400 µm" },
      ],
    },
    {
      title: "Repeatability",
      data: [
        { data: "± 50 µm", span: 3 },
        { data: "± 60 µm", span: 3 },
      ],
    },
    {
      title: "Maksimal hastighed (Kongsberg X Edge)",
      data: [{ data: "30 m/min", span: 6 }],
    },
    {
      title: "Maksimal acceleration (Kongsberg X Edge)",
      data: [{ data: "3 m/s<sup>2</sup>", span: 6 }],
    },
    {
      title: "Maksimal hastighed (Kongsberg X) (4)",
      data: [{ data: "50 m/min", span: 6 }],
    },
    {
      title: "Maksimal acceleration (Kongsberg X)",
      data: [
        { data: "5.6 m/s<sup>2</sup>", span: 3 },
        { data: "5.4 m/s<sup>2</sup>", span: 3 },
      ],
    },
    {
      title: "Vertical tool force",
      data: [
        {
          data:
            "Standard tool stations: 220 N. PowerHead crease station: 500 N",
          span: 6,
        },
      ],
    },
    {
      title: "Antal vakuum sektioner",
      data: [
        { data: "2", span: 2 },
        { data: "4", span: 4 },
      ],
    },
    {
      title: "Standard traverse clearance (5)",
      data: [
        {
          data: "50 mm or 95 mm, depending on Application Kit and model size",
          span: 6,
        },
      ],
    },
    {
      title: "Optional traverse clearance (5)",
      data: [
        {
          data:
            "High clearance 95 mm, available for models with 50 mm clearance",
          span: 6,
        },
      ],
    },
  ],
}
*/

const KongsbergTableSpecifications = ({ table }) => {
  const specification = table.technicalSpecification
  return (
    <div className="container mx-auto my-16">
      <div className="block mx-auto w-16 bg-brand-500 h-1.5 text-center mb-3" />
      <h2 className="heading-lg text-center">
        Modeller og tekniske specifikationer
      </h2>

      {specification && (
        <div className="overflow-auto max-w-full">
          <table
            className="branded-table w-full mt-16 mb-2"
            style={{ tableLayout: "fixed", minWidth: "640px" }}
          >
            <thead>
              <tr>
                <td style={{ border: "none" }}></td>
                <td style={{ border: "none" }}></td>
                {specification.headers.map((header, i) => (
                  <th key={i}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {specification.rows.map((row, i) => (
                <RowRenderer
                  key={i}
                  row={row}
                  headers={specification.headers}
                />
              ))}
            </tbody>
          </table>
        </div>
      )}
      {specification &&
        specification.disclaimers.map((d, i) => (
          <p className="text-sm text-gray-500" key={i}>
            {d}
          </p>
        ))}
    </div>
  )
}

const RowRenderer = ({ row, headers }) => {
  return (
    <tr>
      <th colSpan={2}>{row.title}</th>
      {row.data.map(item => (
        <td
          colSpan={item.span || 1}
          dangerouslySetInnerHTML={{ __html: item.data }}
        />
      ))}
    </tr>
  )
}

export default KongsbergTableSpecifications

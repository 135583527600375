import { getImage, GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const KongsbergTableToolHeads = ({ table }) => (
  <div className="container mx-auto my-16">
    <div className="block mx-auto w-16 bg-brand-500 h-1.5 text-center mb-3" />
    <h2 className="heading-lg text-center">Tool heads</h2>

    <div className="grid grid-cols-12 pb-20">
      {table.toolHeadsSection.toolHeads.map((toolHead, i) => (
        <div
          key={i}
          className={`-mb-20 grid grid-cols-1 md:grid-cols-2 gap-4 items-center col-span-12 col-start-1 md:col-span-10 lg:col-span-8 ${
            i % 2 === 0 ? "lg:col-start-2" : "md:col-start-2 lg:col-start-4"
          }`}
        >
          <div
            className={`${i % 2 === 0 ? "md:order-first" : "md:order-last"}`}
          >
            <GatsbyImage
              image={getImage(toolHead.image.staticFile)}
              alt={toolHead.image.alternativeText}
              className="w-full animate-cloudy"
              style={{ animationDelay: `${-i * 0.5}s` }}
            />
          </div>
          <div className="mb-16">
            <h3 className="heading-sm">{toolHead.name}</h3>
            <p>{toolHead.description}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
)

export default KongsbergTableToolHeads

import React from "react"
import { LightBulbIcon } from "@heroicons/react/solid"
import SectionRenderer from "../../components/sections"

const KongsbergTableOverview = ({ table }) => (
  <>
    <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 my-16 gap-4">
      {table.usedFor && (
        <div className="md:col-span-3 mb-6">
          <div className="block mx-auto w-16 bg-brand-500 h-1.5 text-center mb-3" />
          <h2 className="heading-lg text-center">
            {table.name.split(" ").slice(0, -1)}{" "}
            <span className="text-brand-500">
              {table.name.split(" ").pop()}
            </span>{" "}
            til
          </h2>
        </div>
      )}

      {table.usedFor &&
        table.usedFor.ideas.map((idea, i) => (
          <div key={i} className="p-6 pt-12 pb-10 bg-brand-100">
            <h3 className="heading-sm text-center mb-6">{idea.title}</h3>
            <div className="grid grid-cols-1 gap-4">
              {idea.list.split("\n").map((item, i) => (
                <div key={i} className="flex items-start">
                  <LightBulbIcon className="flex-shrink-0 w-5 h-5 text-brand-500 mr-3" />
                  <p>{item}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
    </div>
    <SectionRenderer sections={table.sections} />
  </>
)

export default KongsbergTableOverview
